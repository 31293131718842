





































































































































































































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import {
  CANCEL_MENTOR_REQUEST,
  GET_ALL_MENTORS,
  GET_OWN_MENTORS,
  GET_OWN_SENT_OPEN_MENTOR_INQUIRIES,
  SEND_MENTOR_REQUEST
} from '@/store/mentor/actions.type'
import { MentorData, MentorInquiry } from '@/store/mentor/types'
import { GET_COMPETENCES_TO_IMPROVE_OWN } from '@/store/kode/actions'
import { SimpleServerResponse, User } from '@/store/types'
import { CompetenceImprovement, KodeStatusDTO } from '@/store/kode/types'
import { KeyValue } from '@/store/commonFormInput/types'
import ResultsPage from '@/components/search-results/ResultsPage.vue'
import MentorSearchResult from '@/components/search-results/MentorSearchResult.vue'
import InfoBox from '@/components/hszg/InfoBox.vue'
import BaseForm from '@/components/forms/BaseForm.vue'
import SearchResult from '@/components/search-results/SearchResult.vue'
import Competence from '@/components/hszg/Competence.vue'
import ConfirmationModal from '@/components/common/ConfirmationModal.vue'
import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
import InquiryModal from '@/components/forms/InquiryModal.vue'
import MentoringPageLayout from '@/views/layouts/MentoringPageLayout.vue'
import { GET_PROFILE_KODE_STATUS } from '@/store/user/actions.type'
import { FINISH } from '@/common/kodeStatus'
import TextCreatorForSubmitSuccess from '@/components/forms/getHeadlineAndInfoForSubmitSuccess'

@Component({
  components: {
    LoadingSpinner,
    ConfirmationModal,
    Competence,
    SearchResult,
    BaseForm,
    InfoBox,
    MentorSearchResult,
    ResultsPage,
    MentoringPageLayout,
    InquiryModal
  }
})
// This component is used in the mentoring page to display all content around mentors (my mentors, possible mentors, mentor requests).
// Uses ResultsPage, SearchResult and MentorSearchResult components
// @group MENTORING
export default class MentorsPage extends Vue {
  @Action(GET_ALL_MENTORS)
  public getAllMentors: () => Promise<Array<MentorData>>

  @Action(GET_OWN_MENTORS)
  public getOwnMentors: () => Promise<Array<MentorData>>

  @Action(GET_OWN_SENT_OPEN_MENTOR_INQUIRIES)
  public getOwnSentOpenMentorInquiries: () => Promise<Array<MentorInquiry>>

  @Action(GET_COMPETENCES_TO_IMPROVE_OWN)
  public getCompetencesToImproveOwn: () => Promise<SimpleServerResponse>

  @Action(SEND_MENTOR_REQUEST)
  public sendMentorRequest: (inquiry: MentorInquiry) => Promise<SimpleServerResponse>

  @Action(CANCEL_MENTOR_REQUEST)
  public cancelMentorRequest: (requestId: string) => Promise<SimpleServerResponse>

  @Action(GET_PROFILE_KODE_STATUS)
  public getProfileKodeStatus: () => Promise<KodeStatusDTO>

  @Getter('userGetter') userGetter: User

  // if a h2 headline is shown - is currently used in combination with a right for component arrangement reasons
  @Prop()
  noHeadline: boolean

  private mentors: Array<MentorData> = []
  private filteredMentors: Array<MentorData> = []
  private ownMentors: Array<MentorData> = []
  private mentorRequests: Array<MentorInquiry> = []

  isLoading = false
  showErrorMessage = false
  errorMessage = ''

  improvementData: CompetenceImprovement = {}
  filteredByOwnCompetences = false

  selectedInquiryId = ''

  inquiry: MentorInquiry = {
    inquiryText: '',
    mentorId: ''
  }

  shortMessageWarning = false

  maxPerColumn = 1

  created (): void {
    this.loadData()
  }

  loadData (): void {
    this.isLoading = true
    this.getProfileKodeStatus().then(data => {
      if (data.kodeStatus === FINISH) {
        Promise.all([
          this.getAllMentors(),
          this.getCompetencesToImproveOwn(),
          this.getOwnMentors(),
          this.getOwnSentOpenMentorInquiries()
        ]).then((data) => {
          this.mentors = data[0]
          this.ownMentors = data[2]
          this.mentorRequests = data[3]
          this.filterAvailable()
          this.improvementData = data[1].content
        }).catch(error => {
          const textCreator = new TextCreatorForSubmitSuccess<SimpleServerResponse, keyof SimpleServerResponse>(error, 'job-ad.save-messages.')
          this.errorMessage = textCreator.getInfo()
          this.showErrorMessage = true
        }).finally(() => {
          this.isLoading = false
        })
      } else {
        this.errorMessage = this.$t('warning.no-access-without-kode-status').toString()
        this.showErrorMessage = true
        this.isLoading = false
      }
    }).catch(error => {
      error.message = 'mentoring.' + error.message
      const textCreator = new TextCreatorForSubmitSuccess<SimpleServerResponse, keyof SimpleServerResponse>(error, 'mentoring.')
      this.errorMessage = textCreator.getErrorMessage()
      this.showErrorMessage = true
      this.isLoading = false
    })
  }

  filterAvailable (): void {
    // filter out own profile, own mentors and requested mentors
    this.mentors = this.mentors.filter(mentor => mentor.accountId !== this.userGetter?.appUserId && mentor.mentoringStatus !== 'INQUIRED' && mentor.mentoringStatus !== 'ACTIVE')
    this.filteredMentors = this.mentors
  }

  filterByOwnCompetences (): void {
    if (this.improvementData.baseCompetenceToImprove && this.filteredMentors) {
      this.filteredMentors = this.filteredMentors.filter(mentor => mentor.baseCompetences && mentor.baseCompetences.some((el: KeyValue | string) => el === this.improvementData.baseCompetenceToImprove))
    }
    if (this.improvementData.competencesToImprove?.length && this.filteredMentors) {
      this.filteredMentors = this.filteredMentors.filter(mentor => mentor.competences && mentor.competences.some((el: KeyValue | string) => typeof el === 'string' ? this.improvementData.competencesToImprove?.includes(el) : false))
    }
    this.filteredByOwnCompetences = true
  }

  removeFilter (): void {
    this.filteredMentors = this.mentors
    this.filteredByOwnCompetences = false
  }

  sendMentorInquiry (inputNeeded: boolean, id: string, text?: string | null): void {
    if (inputNeeded) {
      // open modal and save mentor id
      this.shortMessageWarning = false
      this.inquiry.mentorId = id
      this.$bvModal.show('request-mentor-modal')
    } else {
      if (text && text.trim().length > 0) {
        this.inquiry.inquiryText = text
        this.sendMentorRequest(this.inquiry).then(() => {
          this.loadData()
          this.$root.$emit('alert', this.$i18n.t('info.request-success'))
        }).catch(error => {
          this.errorMessage = error
          this.showErrorMessage = true
        })
      } else {
        this.shortMessageWarning = true
      }
    }
  }

  cancelMentorInquiry (confirmationNeeded: boolean, id: string): void {
    if (confirmationNeeded) {
      // open confirmation modal and save mentor id
      this.selectedInquiryId = id
      this.$bvModal.show('confirmation-modal-cancel-mentor-request')
    } else {
      // cancel request with id
      this.cancelMentorRequest(id).then(() => {
        this.loadData()
        this.$root.$emit('alert', this.$i18n.t('info.request-cancel-success'))
      }).catch(error => {
        this.errorMessage = error
        this.showErrorMessage = true
      })
    }
  }
}
