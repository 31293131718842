





















































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import SearchResult from '@/components/search-results/SearchResult.vue'
import { MenteeData } from '@/store/mentor/types'
import ProfilePicture from '@/components/common/ProfilePicture.vue'

@Component({
  components: { ProfilePicture, SearchResult }
})
// Search result displaying mentees (used on the mentoring page for mentors)
// @group MENTORING
export default class MenteeSearchResult extends Vue {
  // mentee content (name, competences, etc.)
  @Prop()
  item: MenteeData

  // how many results per column are displayed, must be the same as the surrounding results page component
  @Prop({ default: 1, validator: function (value) { return value >= 1 && value <= 4 } })
  maxPerColumn: number
}
