var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('search-result',{attrs:{"max-per-column":_vm.maxPerColumn},scopedSlots:_vm._u([{key:"sideElement",fn:function(){return [_c('profile-picture',{attrs:{"id":_vm.item.profileStudentId,"role":"MENTOR"}})]},proxy:true},{key:"title",fn:function(){return [_c('router-link',{attrs:{"to":{
        name: 'talent-profile', params: {
          id: _vm.item.profileStudentId
        },
        query: {
          role: 'MENTOR'
        }
      }}},[(_vm.item.title && _vm.item.title !== 'EMPTY_DUMMY')?[_vm._v(" "+_vm._s(_vm.$t('personal-information.TITLES.' + _vm.item.title))+" ")]:_vm._e(),_vm._v(" "+_vm._s(_vm.item.firstName)+" "+_vm._s(_vm.item.lastName)+" ")],2)]},proxy:true},{key:"subtitle",fn:function(){return [_c('div',{staticClass:"mt-1 mb-2"},_vm._l((_vm.item.baseCompetences),function(comp,index){return _c('span',{key:'base-competence-' + index},[_c('b-badge',{staticClass:"mr-1 mb-1"},[_vm._v(" "+_vm._s(_vm.$t('kode.base-competences.' + comp + '.long'))+" ")])],1)}),0),_c('div',_vm._l((_vm.item.competences),function(comp,index){return _c('span',{key:'competence-' + index},[_c('b-badge',{staticClass:"mr-1 mb-1"},[_vm._v(" "+_vm._s(_vm.$t('all-competences.' + comp + '.title'))+" ")])],1)}),0)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }