



























































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import SearchResult from '@/components/search-results/SearchResult.vue'
import { MentorData } from '@/store/mentor/types'
import { Action } from 'vuex-class'
import { LOAD_IMAGE } from '@/store/user/actions.type'
import { FileDto, FileRequest } from '@/store/types'
import ProfilePicture from '@/components/common/ProfilePicture.vue'
import { shortenString } from '@/common/globals'

@Component({
  components: { ProfilePicture, SearchResult }
})
// Search result displaying mentors (used on the mentoring page for mentees)
// @group MENTORING
export default class MentorSearchResult extends Vue {
  // mentor content (name, competences, etc.)
  @Prop()
  item: MentorData

  // how many results per column are displayed, must be the same as the surrounding results page component
  @Prop({ default: 1, validator: function (value) { return value >= 1 && value <= 4 } })
  maxPerColumn: number

  // if the button to request this mentor is available
  @Prop()
  requestable: boolean

  // if the button to cancel a request this mentor is available
  @Prop()
  cancelable: boolean

  @Action(LOAD_IMAGE)
  public loadImage: (request: FileRequest) => Promise<FileDto>

  image: FileDto = { base64Content: '', filename: '' }

  mounted (): void {
    this.loadImage({ role: 'MENTOR', id: this.item.accountId }).then((data: FileDto) => {
      this.image = data
    })
  }

  getShortString (description: string, length: number, suffix?: string) {
    return shortenString(description, length, suffix)
  }
}
