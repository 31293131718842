





























import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import MentorsPage from '@/components/mentoring/MentorsPage.vue'
import MenteesPage from '@/components/mentoring/MenteesPage.vue'
import { hasRight } from '@/utils/rights'
import Rights from '@/common/rights'
@Component({
  components: { MenteesPage, MentorsPage }
})
export default class Mentoring extends Vue {
  get mentorOwnWriteRight (): boolean {
    return hasRight(Rights.MENTOR_OWN_WRITE)
  }

  get mentorReadRight (): boolean {
    return hasRight(Rights.MENTOR_READ)
  }

  get mentorRequestRight (): boolean {
    return hasRight(Rights.MENTOR_REQUEST)
  }
}
