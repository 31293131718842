

























































































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import ResultsPage from '@/components/search-results/ResultsPage.vue'
import { MenteeData, MentorInquiry } from '@/store/mentor/types'
import { Action } from 'vuex-class'
import {
  ACCEPT_MENTOR_REQUEST,
  DECLINE_MENTOR_REQUEST, GET_OWN_MENTEES,
  GET_OWN_RECEIVED_OPEN_MENTOR_INQUIRIES
} from '@/store/mentor/actions.type'
import { SimpleServerResponse } from '@/store/types'
import InfoBox from '@/components/hszg/InfoBox.vue'
import ConfirmationModal from '@/components/common/ConfirmationModal.vue'
import SearchResult from '@/components/search-results/SearchResult.vue'
import MenteeSearchResult from '@/components/search-results/MenteeSearchResult.vue'
import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
import MentoringPageLayout from '@/views/layouts/MentoringPageLayout.vue'

@Component({
  components: { LoadingSpinner, MenteeSearchResult, SearchResult, ConfirmationModal, InfoBox, ResultsPage, MentoringPageLayout }
})
// This component is used in the mentoring page to display all content around mentees (my mentees, requests from mentees).
// Uses ResultsPage, SearchResult and MenteeSearchResult components
// @group MENTORING
export default class MenteesPage extends Vue {
  @Action(GET_OWN_MENTEES)
  public getOwnMentees: () => Promise<Array<MenteeData>>

  @Action(GET_OWN_RECEIVED_OPEN_MENTOR_INQUIRIES)
  public getOwnReceivedOpenMentorInquiries: () => Promise<Array<MentorInquiry>>

  @Action(ACCEPT_MENTOR_REQUEST)
  public acceptMenteeRequest: (requestId: string) => Promise<SimpleServerResponse>

  @Action(DECLINE_MENTOR_REQUEST)
  public declineMenteeRequest: (requestId: string) => Promise<SimpleServerResponse>

  // if a h2 headline is shown - is currently used in combination with a right for component arrangement reasons
  @Prop()
  noHeadline: boolean

  private ownMentees: Array<MenteeData> = []
  private menteeRequests: Array<MentorInquiry> = []

  isLoading = false
  showErrorMessage = false
  errorMessage = ''

  selectedRequestId = ''
  accepting = false

  created (): void {
    this.loadData()
  }

  loadData (): void {
    this.isLoading = true
    Promise.all([
      this.getOwnReceivedOpenMentorInquiries(),
      this.getOwnMentees()
    ]).then((data) => {
      this.menteeRequests = data[0]
      this.ownMentees = data[1]
    }).catch(error => {
      this.errorMessage = error
      this.showErrorMessage = true
    }).finally(() => {
      this.isLoading = false
    })
  }

  answerMenteeInquiry (inputNeeded: boolean, accept: boolean, id: string): void {
    if (inputNeeded) {
      // open modal and save id
      this.selectedRequestId = id
      this.accepting = accept
      this.$bvModal.show('confirmation-modal-answer-mentee-inquiry')
    } else {
      if (accept) {
        // accept request
        this.acceptMenteeRequest(id).then(() => {
          this.loadData()
          this.$root.$emit('alert', this.$i18n.t('inquiries.request-accepted'))
        }).catch(error => {
          this.errorMessage = error
          this.showErrorMessage = true
        })
      } else {
        // decline request
        this.declineMenteeRequest(id).then(() => {
          this.loadData()
          this.$root.$emit('alert', this.$i18n.t('inquiries.request-declined'))
        }).catch(error => {
          this.errorMessage = error
          this.showErrorMessage = true
        })
      }
    }
  }
}
